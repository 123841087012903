import { testExpression, validCnpjExpression } from '../misc/regexp'
import { format, parseISO } from 'date-fns'
import router from '@/router'

export const dateTimeFormatter = (value) => {
  if (!value) return '';
  const date = new Date(value);
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return date.toLocaleString('pt-BR', options);
};

export const brazilianFormattedDate = (value) =>
  value ? format(parseISO(value), 'dd/MM/yyyy') : '-'

export const brazilianFormattedDateHour = (value) =>
  value ? format(parseISO(value), 'dd/MM/yyyy HH:mm') : '-'

export const moneyFormat = (value, minimumFractionDigits = 2) => {
  if (!value) return 'R$ 0'

  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits,
  })
}

export const moneyFormatDatatable = (value, key, row, minimumFractionDigits = 2) => {
  if (!value) return 'R$ 0,00'

  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits,
  })
}

export const emptyFormatter = (value) => {
  if (!value) return '-'

  return value
}

export const cnpjFormatter = (value) => {
  if (!value) return '-'

  if (!testExpression(validCnpjExpression, value)) return value

  return value.replace(validCnpjExpression, '$1.$2.$3/$4-$5')
}

export const prazoFormatter = (value) => {
  if (!value) return ''

  return `${value} dias`
}

export const percentageFormatter = (value) => {
  if (!value) return ''

  return `${parseFloat(value).toFixed(2)}%`
}

export const dateFormatter = (value) => {
  if (!value) return ''

  return value.split('-').reverse().join('/')
}

export const stringToDateFormatter = (value) => {
  if (!value) return ''

  return new Date(`${value.split('/').reverse().join('-')}T00:00:00-0300`)
}

export const remessaStatusBadge = (value) => {
  if (!value) return ''

  const variant = {
    importado: 'dark',
    disponivel: 'info',
    aguardando_assinatura: 'warning',
    assinada: 'success',
    concluido: 'success',
    erro: 'danger',
    cancelada: 'danger',
  }

  const status = {
    importado: 'Importado',
    disponivel: 'Disponivel',
    aguardando_assinatura: 'Aguardando assinatura',
    assinada: 'Assinada',
    concluido: 'Concluída',
    erro: 'Erro',
    cancelada: 'Cancelada',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const tituloStatusImportacaoBadge = (value) => {
  if (!value) return ''

  const variant = {
    pendente: 'info',
    importado: 'success',
    rejeitado: 'danger',
  }

  const status = {
    pendente: 'Pendente',
    importado: 'Importado',
    rejeitado: 'Rejeitado',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const tituloStatusLiquidacaoBadge = (value) => {
  if(!value) return '';
  const variant = {
    vazio: 'warning',
    aguardando_pagamento: 'info',
    pago: 'success',
    atrasado: 'warning',
    cancelado: 'danger',
    perdido: 'danger',
  }

  const status = {
    vazio: 'Pendente',
    aguardando_pagamento: 'Aguardando pagamento',
    pago: 'Pago',
    atrasado: 'Atrasado',
    cancelado: 'Cancelado',
    perdido: 'Perdido',
  }

  if(value === null || value === undefined) {
    return `<span class="badge badge-light-${variant['vazio']} badge-text-dark border-${variant['vazio']} badge-pill">${status['vazio']}</span>`
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const situationStatusFormatter = (value) => {
  if (!value) return ''

  const variant = {
    ativo: 'success',
    inativo: 'danger',
  }

  const status = {
    ativo: 'Ativo',
    inativo: 'Inativo',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const userStatusBadge = (value) => {
  if (!value) return ''

  const variant = {
    ativo: 'success',
    bloqueado: 'danger',
  }

  const status = {
    ativo: 'Ativo',
    bloqueado: 'Bloqueado',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const returnFileStatusFormatter = (value) => {
  if (!value) return ''

  const variant = {
    processando: 'dark',
    processado: 'success',
    importado: 'success',
    erro: 'danger',
  }

  const status = {
    processando: 'Processando',
    processado: 'Processado',
    importado: 'Importado',
    erro: 'Erro',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const exceptionFileStatusFormatter = (value) => {
  if (!value) return ''


  const variant = {
    pendente: 'light',
    processando: 'dark',
    concluido: 'success',
    erro: 'danger',
    operacoes_indefinidas: 'warning'
  }

  const status = {
    pendente: 'Pendente',
    processando: 'Processando',
    concluido: 'Concluido',
    erro: 'Erro',
    operacoes_indefinidas: 'Operações Indefinidas',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const exceptionOperationStatusFormatter = (value) => {
  if (!value) return ''

  const variant = {
    importado: 'success',
    rejeitado: 'danger',
  }

  const status = {
    importado: 'Importado',
    rejeitado: 'Rejeitado',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const exceptionOperationTipoFormatter = (value) => {
  return {
    cancelamento: 'Cancelamento',
    reducao_valor: 'Reducao Valor',
    alongamento_prazo: 'Alongamento Prazo',
    recompra: 'Recompra',
    baixa: 'Baixa',
    liquidacao: 'Liquidacao',
  }[value]
}

export const trueFalseToText = (value) => {
  return value ? 'Sim' : 'Não'
}

export const reasonFormatDatatable = (value) => {
  if (!value) return ''

  const reasons = {
    cancelado: 'Cancelado',
    reducao_valor: 'Redução de valor',
    alongamento_prazo: 'Alongamento prazo',
    pago: 'Pago',
    atraso: 'Atraso',
    diferenca_liquidacao: 'Diferença na liquidação',
  }

  return reasons[value]
}

export const tipoDeOcorrenciaDatatable = (value) => {
  if (!value) return ''

  const reasons = {
    cancelamento: 'Cancelamento',
    prorrogacao: 'Prorrogação',
    devolucao_parcial: 'Devolução parcial',
  }

  return reasons[value]
}

export const statusImportacaoFormatter = (value) => {
  if (!value) return ''

  const variant = {
    pendente: 'warning',
    processado: 'dark',
    importado: 'success',
    rejeitado: 'danger',
  }

  const status = {
    importado: 'Importado',
    rejeitado: 'Rejeitado',
    pendente: 'Pendente',
    processado: 'Processado',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const statusRedescontoFormatter = (value) => {
  if (!value) return ''

  const variant = {
    pendente: 'warning',
    processando: 'dark',
    executado: 'success',
    rejeitado: 'danger',
  }

  const status = {
    executado: 'Executado',
    rejeitado: 'Rejeitado',
    pendente: 'Pendente',
    processando: 'Processando',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const linkToRemessa = (value) => {
  if(router.currentRoute.name === 'partner.redescontos.index') {
    return `<a href="/parceiro/remessas/${value.id}" class="remessa-link">${value.numero}</a>`
  } else {
    return `<a href="/admin/concessoes/${value.id}" class="remessa-link">${value.numero}</a>`
  }
}
